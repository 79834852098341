<template>
  <div class="w_login">
    <div class="con">
      <template v-if="$route.name != 'message'&& $route.name != 'collect'">
        <img class="back" src="../../assets/images/blue_back.png" @click="$router.go(-1);" />
      </template>
      <div class="box">
        <img class="title" src="../../assets/images/login_title.png" />
        <div class="item_box">
          <div class="item_box_title">账号：</div>
          <input v-model="data.mobile" class="item_box_text" placeholder="请输入" />
        </div>
        <div class="item_box">
          <div class="item_box_title">密码：</div>
          <input type="password" v-model="data.password" class="item_box_text" placeholder="请输入" />
        </div>

        <div class="btn" @click="Login">登录</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { showToast } from 'vant';
import 'vant/es/toast/style';
import { reactive, getCurrentInstance } from 'vue';
import { useRouter,useRoute } from 'vue-router';
import { localStorage } from '../../utils/storage';

const $router = useRouter();
const $route = useRoute();
const _this = getCurrentInstance().appContext.config.globalProperties;

const data = reactive({
  mobile: '',
  password:''
});

function Login() {
  if(data.mobile == ''|| data.password == ''){
    showToast('请输入手机号或密码');
  }else {
    //15820415459 aa123123
    var _url = "/tools/submit_ajax.ashx?action=user_login";
    //var _getUserInfourl = "/tools/submit_ajax.ashx?action=get_user_info";
    _this.$http.post(_url, { u_name: data.mobile, u_pwd: data.password }).then(function (res) {
      if (res.data.status == 1) {
        showToast('登录成功');
        localStorage.set('user_info', res.data.user_info,60*60*24*3);
        _this.emitter.emit('isLogin', true);
        $router.go(-1);
      } else {
        showToast(res.data.msg);
      }
    }.bind(this)).catch(function () {
    });
  }
}


</script>